<template>
  <div>
    <loading-screen :is-loading="isSaving"></loading-screen>
    <section class="bg-dark pb-3 pt-5">
      <div class="container py-5">
        <div class="row align-items-center mt-2 mt-md-0 pt-md-4 py-lg-2">
          <div class="col-md-12 text-center order-md-1"
          ><h1 class="display-5 pt-2 text-light pb-0 mb-4 mb-lg-4">
            {{ isLoading ? 'Loading...' : details['job_title'] }}
          </h1>
          </div>
        </div>
      </div>
    </section>
    <section class="position-relative bg-white rounded-xxl-4 zindex-5" style="margin-top: -30px;">
      <div class="py-1">
        <div class="row justify-content-center">
          <div class="col-lg-11 col-xl-10">

            <!-- Loading -->
            <div class="loading mt-3" v-if="isLoading">
              <spinner></spinner>
            </div>
            <!-- / Loading -->

            <!-- Loaded -->
            <div v-if="!isLoading">
              <!-- Common Replies (Shortcuts) -->
              <div class="options-selector">
                <h4 class="mb-5">Please select an option below to provide a status update</h4>

                <!-- Presets -->
                <div :class="getOptionClass(option)" v-for="option in details['status_update_options']"
                     @click.prevent="openModal(option)"
                     :key="option.id">
                  {{ option.description }}
                </div>
                <!-- / Presets -->
              </div>
              <!-- / Common Replies -->

              <!-- Custom Reply -->
              <div class="custom-reply">
                <div class="btn btn-link text-primary" @click.prevent="openModal">
                  Click here to provide a custom update
                </div>
              </div>
              <!-- Custom Reply -->

            </div>
            <!-- / Loaded -->

            <!-- Modal -->
            <vue-final-modal v-model="modalVisible">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header border-bottom-0">
                    <h5 class="modal-title">
                      Provide Status Update
                    </h5>
                    <button type="button" class="btn-close" aria-label="Close" @click="closeModal"></button>
                  </div>
                  <div class="modal-body">

                    <!-- Tone Selector -->
<!--                    <div class="tone-selector">-->

<!--                      <p class="mb-3">How well is the project going?</p>-->

<!--                      <div class="row">-->
<!--                        <div class="col-md-4">-->
<!--                          <div class="tone-option btn btn-outline-primary" :class="{'btn-primary': form.tone >  0}"-->
<!--                               @click.prevent="setTone(1)">-->
<!--                            😀-->
<!--                          </div>-->
<!--                        </div>-->
<!--                        <div class="col-md-4">-->
<!--                          <div class="tone-option btn btn-outline-primary" :class="{'btn-primary': form.tone == 0}"-->
<!--                               @click.prevent="setTone(0)"-->
<!--                          >-->
<!--                            😐-->
<!--                          </div>-->
<!--                        </div>-->
<!--                        <div class="col-md-4">-->
<!--                          <div class="tone-option btn btn-outline-primary" :class="{'btn-primary': form.tone < 0}"-->
<!--                               @click.prevent="setTone(-1)"-->
<!--                          >-->
<!--                            😟-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->

<!--                    </div>-->
                    <!-- / Tone Selector -->

                    <!-- Description Entry -->
                    <div class="description-entry">

<!--                      <p class="mb-3">Please provide an update</p>-->

                      <input v-model="form.description" placeholder="The project is going well..." type="textarea" class="form-control">

                    </div>
                    <!-- / Description Entry -->

                    <!-- Save -->
                    <div class="actions">
                      <a href="#" @click.prevent="attemptSave" class="btn btn-primary">Save Update</a>
                    </div>
                    <!-- / Save -->

                  </div>
                </div>
              </div>
            </vue-final-modal>
            <!-- / Modal -->

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import statusUpdates from "../api/status-updates";
import {useToast} from "vue-toastification";
import Spinner from "../components/Spinner";
import LoadingScreen from "../components/LoadingScreen";
import Forms from "../mixins/Forms";
const _ = require('lodash');

const toast = useToast();

export default {
  name: "StatusUpdate",
  components: {LoadingScreen, Spinner},
  mixins: [Forms],
  data() {
    return {
      isLoading: false,
      details: {},
      updateCode: this.$route.params.updateCode,

      form: {
        tone: 0,
        description: ''
      },
      modalVisible: false,

      isSaving: false
    }
  },
  created() {
    this.loadDetails();
  },
  methods: {
    loadDetails() {
      let vm = this;
      vm.isLoading = true;
      statusUpdates.getDetailsByUpdateCode(this.updateCode).then(r => {
        vm.isLoading = false;
        vm.details = r.data;

        // Preselect a shortcut
        if (this.$route.query.statusUpdateOptionId) {
          let option = _.find(vm.details['status_update_options'], {id: Number(this.$route.query.statusUpdateOptionId)});
          if (option) {
            vm.openModal(option);
          }
        }

        // Preselect something else
        if (this.$route.query.somethingElse && Boolean(this.$route.query.somethingElse) === true) {
          vm.openModal();
        }

      }).catch(e => {
        vm.isLoading = false;
        console.log(e);
        toast.error('That status update invite has expired or does not exist');
        vm.$router.push('/');
      });
    },

    getOptionClass(option) {
      let c = 'option btn btn-primary ms-3 me-3';
      if (option.tone > 0) {
        c = c + 'positive';
      }
      if (option.tone === 0) {
        c = c + 'netural';
      }
      if (option.tone < 0) {
        c = c + 'negative';
      }
      return c;
    },

    openModal(option = null) {
      if (option) {
        this.form.tone = option.tone;
        this.form.description = option.description;
      } else {
        this.form = {
          tone: 0,
          description: ''
        };
      }
      this.modalVisible = true;
    },

    closeModal() {
      this.modalVisible = false;
    },

    setTone(tone) {
      this.form.tone = tone;
    },

    getValidationErrors() {
      let errors = [];

      if (this.form.tone == null) {
        errors.push('Please select an update tone');
      }

      if (this.form.description == null || this.form.description.trim().length == 0) {
        errors.push('Please provide a comment');
      }

      return errors;
    },

    attemptSave() {
      let vm = this;

      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        toast.error(validationErrors[0]);
        return false;
      }

      vm.save();
    },

    save() {
      let vm = this;
      vm.isSaving = true;
      statusUpdates.createUpdateFromUpdateCode(vm.updateCode, vm.form.tone, vm.form.description).then(r => {
        toast.success('Status update provided successfully');
        vm.isSaving = false;
        this.$router.push('/jobs/' + vm.details.job_id);
      }).catch(e => {
        vm.isSaving = false;
        console.log(e);
        vm.setAndNotifyErrors(e);
      });
    }
  }
}
</script>

<style scoped lang="scss">
.loading {
  text-align: center;
}

.options-selector {
  text-align: center;
  margin-top: 50px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.option {
  margin-bottom: 20px;
  color: #fff;
  padding: 16px 30px;
  border-radius: 40px;
  font-weight: 600;

  .emoji {
    margin-right: 5px;
    font-size: 20px;
  }
}

.custom-reply {
  text-align: center;
  margin-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

.tone-option {
  width: 100%;
  font-size: 30px;
}

.description-entry {
  //margin-top: 30px;
}

.actions {
  margin-top: 30px;
}
</style>